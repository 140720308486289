<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap38Generics"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 38: Generics" image-name="generics.jpg" image-alt="Generics"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>We will see how to write generic functions, methods, and types</p></li>
<li><p>We will see how to use those generic constructs in your code</p></li>
<li><p>We will see the traditional use cases of generics: when to use them, when not to use them</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Generic programming</p></li>
<li><p>Type constraints</p></li>
<li><p>Type parameters</p></li>
<li><p>Function</p></li>
<li><p>Method</p></li>
<li><p>Interface</p></li>
</ul>
<div id="introduction" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> Introduction <a href="#introduction"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Since Go’s first release, the community’s need for generics has been strong. As mentioned by Ian Lance Taylor in a talk that a Go user requested it in November 2009<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>! The Go team introduced Generics 1.18 version that was released in March 2022<a href="#fn2" class="footnote-ref" id="fnref2" role="doc-noteref"><sup>2</sup></a></p>
<p>In this chapter, we will cover the topic of generics.</p>
<div id="what-does-generic-mean" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> What does generic mean? <a href="#what-does-generic-mean"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>If we refer to the Cambridge Dictionary, the adjective generic means: relating to or shared by a whole group of similar things; not specific to any particular thing.</p>
<p>Here is an example usage that will make you understand the notion: Jazz is a generic term for a wide range of different styles of music..</p>
<p>If we come back to programming, we can create, for instance, generic functions that will not bind to a specific type of input/output parameters.</p>
<p>When I build a function in Go, I need to specify the type I use for my input parameters and my results. Usually, a function will work for a specific type, for instance, an <span v-highlightjs><code class="go" v-pre style="display: inline">int64</code></span>.</p>
<p>Let’s take a simple example :</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go

// max function returns the maximum between two numbers
func max(a, b int64) int64 {
   if a &gt; b {
      return a
   }
   return b
}</code></pre>
<p>This max function will only work if you input numbers of type int64 :</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go
var a, b int64 = 42, 23
fmt.Println(max(a, b))
// 42</code></pre>
<p>But let’s imagine now that you have numbers of type int32, they are integers, but the type is not int64. If you attempt to feed the max function with int32 numbers, your program will not compile. And this is very fine; int32 and int64 are not the same types.</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go
var c, d int32 = 12, 376
// DOES NOT COMPILE
fmt.Println(max(c, d))
// ./main.go:10:18: cannot use c 
// (variable of type int32) as type int64 in argument to max
// ./main.go:10:21: cannot use d 
// (variable of type int32) as type int64 in argument to max</code></pre>
<p>The idea behind generics is to make that function work for int, int32, int64 but also unsigned integers: uint, uint8, uint16, uint32. Those types are different, but they all share something particular we can compare them the same way.</p>
<div id="why-do-we-need-generics" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Why do we need generics? <a href="#why-do-we-need-generics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We can use this definition from <b-link class="citation" data-cites="jazayeri2003generic" href="#jazayeri2003generic" >[@jazayeri2003generic]</b-link>, which I find quite clear: The goal of generic programming is to express algorithms and data structures in a broadly adaptable, interoperable form that allows their direct use in software construction.</p>
<p>We now understand that generic programming aims to write interoperable and adaptable code. But what does it mean to have an interoperable code?</p>
<p>It means that we want to be able to use the same function for different types that share some common capabilities.</p>
<p>Let’s come back to our previous example: the max function. We could write different versions of it for each integer type. One for uint, one for <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span>, one for <span v-highlightjs><code class="go" v-pre style="display: inline">int32</code></span>, etc...</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go

// maxInt32 function works only for int32
func maxInt32(a, b int32) int32 {
   if a &gt; b {
      return a
   }
   return b
}

// maxUint32 function works only for uint32
func maxUint32(a, b uint32) uint32 {
   if a &gt; b {
      return a
   }
   return b
}</code></pre>
<p>Writing the same function over and over will work, but it is ineffective; why not just one function that will work for all integers? Generics is the language feature that allows that.</p>
<p>Here is the generic version of our <span v-highlightjs><code class="go" v-pre style="display: inline">max</code></span> function:</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go

func maxGeneric[T constraints.Ordered](a, b T) T {
   if a &gt; b {
      return a
   }
   return b
}</code></pre>
<p>And we can use this function like that :</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go

fmt.Println(maxGeneric[int64](a, b))
// 42
fmt.Println(maxGeneric[int32](c, d))
// 376</code></pre>
<p>Or even like that :</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go

fmt.Println(maxGeneric(a, b))
fmt.Println(maxGeneric(c, d))</code></pre>
<div id="but-we-already-have-the-empty-interface-why-do-we-need-those-generics" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> But we already have the empty interface; why do we need those generics? <a href="#but-we-already-have-the-empty-interface-why-do-we-need-those-generics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Do you remember the empty interface:</p>
<pre v-highlightjs><code class="go" v-pre >interface{} </code></pre>
<p>All types implement the empty interface. It means that I can define a new max function that accepts as input elements of type empty interface and returns elements of type empty interface :</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go

func maxEmptyInterface(a, b interface{}) interface{} {
   if a &gt; b {
      return a
   }
   return b
}</code></pre>
<p>Can I do that? No! The program will not compile. We will have the following error :</p>
<pre v-highlightjs><code class="go" v-pre >./main.go:64:5: invalid operation: a &gt; b (operator &gt; not defined on interface) </code></pre>
<p>The empty interface does not define the operator greater than (<span v-highlightjs><code class="go" v-pre style="display: inline">&gt;</code></span>). And we touch here on an important particularity of types: one type can define behaviors, and those behaviors are methods but also operators.</p>
<p>You might ask yourself what exactly an operator is. An operator combines operands. The most known are the ones you can use to compare things :</p>
<pre v-highlightjs><code class="go" v-pre >&gt;, ==, !=, &lt;</code></pre>
<p>When we write:</p>
<pre v-highlightjs><code class="go" v-pre >A &gt; B </code></pre>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">A</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">B</code></span> are operands, and <span v-highlightjs><code class="go" v-pre style="display: inline">&gt;</code></span> is the operator.</p>
<p>So we cannot use the empty interface because it says nothing, and by the way, with Go 1.18, the empty interface now has an alias: any. Instead of using <span v-highlightjs><code class="go" v-pre style="display: inline">interface{}</code></span> you can use any. It will be the same, but please remember that there is a good old empty interface behind any.</p>
<div id="type-parameters-a-way-to-parametrize-functions-methods-and-types." class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Type Parameters: a way to parametrize functions, methods, and types. <a href="#type-parameters-a-way-to-parametrize-functions-methods-and-types."><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Go developers added a new feature to the language named <strong>Type Parameters</strong>.</p>
<p>We can create generic functions or methods by adding type parameters. We use square brackets to add <strong>type parameters</strong> to a regular function. We say we have a generic function/method if we have type parameters.</p>
<pre v-highlightjs><code class="go" v-pre >// generics/first/main.go
package main

import (
   &quot;golang.org/x/exp/constraints&quot;
)

func maxGeneric[T constraints.Ordered](a, b T) T {
   if a &gt; b {
      return a
   }
   return b
}</code></pre>
<p>In the previous snippet, the function maxGeneric is generic. It has one type parameter named T of type constraint constraints.Ordered. This type comes from the package constraints that the Go team provides.</p>
<figure>
<b-img :src="require('@/assets/images/generic-function.jpg')" alt="A generic function with type parameters"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A generic function with type parameters</figcaption>
</figure>
<p>Let’s align on some vocabulary :</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">[T constraints.Ordered]</code></span> is the type parameter list.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> is the type parameter identifier (or name)</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">constraints.Ordered</code></span> is the type constraint.</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">T constraints.Ordered</code></span> is a type parameter declaration.</p></li>
<li><p>Note that the identifier of the <strong>type parameter</strong> is positioned <u>before</u> the <strong>type constraint</strong>.</p></li>
</ul>
<p>A generic function has a list of type parameters. Each type parameter has a type constraint, just as each ordinary parameter has a type<a href="#fn3" class="footnote-ref" id="fnref3" role="doc-noteref"><sup>3</sup></a>.</p>
<figure>
<b-img :src="require('@/assets/images/generic-two-type-parameters.jpg')" alt="A generic function can have several type parameters"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A generic function can have several type parameters</figcaption>
</figure>
<div id="what-is-a-type-constraint" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> What is a type constraint? <a href="#what-is-a-type-constraint"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>A type constraint (example <span v-highlightjs><code class="go" v-pre style="display: inline">constraints.Ordered</code></span>) is an interface that defines the set of permissible type arguments for the respective type parameter and controls the operations supported by values of that type parameter.<b-link class="citation" data-cites="go-specs" href="#go-specs" >[@go-specs]</b-link>.</p>
<p>The type(s) constraint(s) will restrain the types we can use in our generic function. It gives you the info: can I use this specific type in this generic function.</p>
<p>This definition is a bit hard, but in reality, it’s not that complex; let’s decompose it:</p>
<ul>
<li><p>It should be an interface</p></li>
<li><p>In this interface, we define <strong>the set of permissible type arguments</strong>, all types we can use for this parameter.</p></li>
<li><p>It also dictates the operations supported by values of that type.</p></li>
</ul>
<p>After seeing the theory, let’s take a look at what a type constraint looks like in reality :</p>
<pre v-highlightjs><code class="go" v-pre >// Ordered is a constraint that permits any ordered type: any type
// that supports the operators &lt; &lt;= &gt;= &gt;.
// If future releases of Go add new ordered types,
// this constraint will be modified to include them.
type Ordered interface {
   Integer | Float | ~string
}</code></pre>
<p>So we can see that inside this interface, we do not have methods. Like we have in a traditional interface. Instead of methods, we have one line :</p>
<pre v-highlightjs><code class="go" v-pre >Integer | Float | ~string</code></pre>
<p>You have three elements separated by the pipe character: <span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span>. This character represents a union. We name type terms the elements that form that union. <span v-highlightjs><code class="go" v-pre style="display: inline">Integer</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">Float</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">~string</code></span> are type terms.</p>
<p>A type term is either :</p>
<ul>
<li><p>A single type</p>
<ul>
<li><p>example: <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">Integer</code></span></p></li>
<li><p>It can be a predeclared type or another type</p>
<ul>
<li><p>Here, for instance, <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span> is a predeclared type (It exists in the language by default, like <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span>, .…)</p></li>
<li><p>And for instance, we can have <span v-highlightjs><code class="go" v-pre style="display: inline">Integer</code></span> which is a type that we created.</p></li>
<li><p>Let’s take an example of that with the type <span v-highlightjs><code class="go" v-pre style="display: inline">Foo</code></span> :</p></li>
</ul></li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type Foo interface {
   int | int8
}</code></pre>
<ul>
<li><p>An underlying type</p>
<ul>
<li><p>example: <span v-highlightjs><code class="go" v-pre style="display: inline">~string</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">~uint8</code></span></p></li>
<li><p>You can note the additional tilde <span v-highlightjs><code class="go" v-pre style="display: inline">~</code></span> here.</p></li>
<li><p>The tilde denotes all the types that have a specific underlying type.</p></li>
<li><p>Generally, we do not target a specific type like <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span> even if we can do that; we will prefer to target all the other types that can exist with the underlying type <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span>. By doing so, we cover more types.</p></li>
<li><p>For example: <span v-highlightjs><code class="go" v-pre style="display: inline">~string</code></span> denotes all the types that have the underlying type <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>:</p></li>
</ul></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >type DatabaseDSN string </code></pre>
<p>This type <span v-highlightjs><code class="go" v-pre style="display: inline">DatabaseDSN</code></span> is a new type, but the underlying type is <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span>, so as a consequence, this type fits into <span v-highlightjs><code class="go" v-pre style="display: inline">~string</code></span>.</p>
<p>Let’s take another example to make sure you understand:</p>
<pre v-highlightjs><code class="go" v-pre >type PrimaryKey uint </code></pre>
<p>This type <span v-highlightjs><code class="go" v-pre style="display: inline">PrimaryKey</code></span> is a new type, but the underlying type is <span v-highlightjs><code class="go" v-pre style="display: inline">uint</code></span>, so as a consequence, this type fits into <span v-highlightjs><code class="go" v-pre style="display: inline">~uint</code></span>.</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">~uint</code></span> represents all types with an underlying type <span v-highlightjs><code class="go" v-pre style="display: inline">uint</code></span>.</p>
<figure>
<b-img :src="require('@/assets/images/generics-type-constraint.jpg')" alt="An example of type constraints"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">An example of type constraints</figcaption>
</figure>
<div id="type-constraints-that-you-can-use-right-away" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Type constraints that you can use right away <a href="#type-constraints-that-you-can-use-right-away"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We have the module golang.org/x/exp/constraints that we can use right away that contains useful type constraints :</p>
<p>You will need first to import the package into your code with:</p>
<pre v-highlightjs><code class="go" v-pre >go get golang.org/x/exp/constraints </code></pre>
<p>Then you can use all the constraints.</p>
<ul>
<li><p><strong>Signed</strong>: all signed integers</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">~int | ~int8 | ~int16 | ~int32 | ~int64</code></span></p></li>
<li><p>ex: -10, 10</p></li>
</ul></li>
<li><p><strong>Unsigned</strong>: all signed integers</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">~uint | ~uint8 | ~uint16 | ~uint32 | ~uint64 | ~uintptr</code></span></p></li>
<li><p>ex: 42</p></li>
</ul></li>
<li><p><strong>Integer</strong>, the union of Signed and Unsigned</p>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">Signed | Unsigned</code></span></li>
</ul></li>
<li><p><strong>Float</strong> all floating point numbers</p>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">~float32 | ~float64</code></span></li>
</ul></li>
<li><p><strong>Complex</strong> all complex numbers</p>
<ul>
<li><span v-highlightjs><code class="go" v-pre style="display: inline">~complex64 | ~complex128</code></span></li>
</ul></li>
<li><p><strong>Ordered</strong> all types that we can order</p>
<ul>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Integer | Float | ~string</code></span></p></li>
<li><p>As you note here, this is the union between three types integers, float, and strings</p></li>
</ul></li>
</ul>
<div id="how-to-call-a-generic-function-or-method" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> How to call a generic function or method? <a href="#how-to-call-a-generic-function-or-method"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s take our example function maxGeneric again:</p>
<pre v-highlightjs><code class="go" v-pre >func maxGeneric[T constraints.Ordered](a, b T) T {...} </code></pre>
<p>We have seen in the previous example that to call our maxGeneric we needed to specify the argument type:</p>
<pre v-highlightjs><code class="go" v-pre >var a, b int64 = 42, 23 
maxGeneric[int64](a, b)</code></pre>
<p>It is clear here that the type of T is <span v-highlightjs><code class="go" v-pre style="display: inline">int64</code></span> since we manipulate <span v-highlightjs><code class="go" v-pre style="display: inline">int64</code></span>. Why is it important for the language to determine the type of <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span>, the type of the type parameter? That’s because, in any part of our program, we need to have variables that have a type. When we define our generic function, we add a type parameter that constrains the types we can use. When I define my <span v-highlightjs><code class="go" v-pre style="display: inline">maxGeneric</code></span> function, I only know that I can order the arguments passed to my function. It does not say much more.</p>
<p>When we want to use the function, Go needs to determine what will be concretely the type that we will manipulate. At runtime, the program work on concrete, specific types, not a formal constraint, not a catalog of every type possible.</p>
<div id="type-inference-enjoy-being-lazy" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Type inference: enjoy being lazy <a href="#type-inference-enjoy-being-lazy"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the previous snippet, we used :</p>
<pre v-highlightjs><code class="go" v-pre >maxGeneric[int64](a, b) </code></pre>
<p>but we can also directly write:</p>
<pre v-highlightjs><code class="go" v-pre >var a, b int64 = 42, 23
maxGeneric(a, b)</code></pre>
<p>In the last snippet, we did not specify the type parameter of a and b; we let the language infer the type parameter (the type of <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span>). Go will attempt to determine the type parameter based on the context.</p>
<p>This type parameter inference is done at compile time and not runtime.</p>
<p>Note that type parameter inference might not be possible in some cases. We will not deep dive into those exceptions. Most of the time, inference will work, and you will not have to think about it. In addition, there is a strong safeguard because the compiler checks inference.</p>
<div id="types-can-also-have-a-type-parameter-list" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Types can also have a type parameter list! <a href="#types-can-also-have-a-type-parameter-list"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Let’s take an example. Let’s say you want to create a specific type representing all maps with comparable keys and integer values.</p>
<p>We can create a parametrized custom type :</p>
<pre v-highlightjs><code class="go" v-pre >// generics/types
type GenericMap[K constraints.Ordered, V constraints.Integer] map[K]V</code></pre>
<p>We have a new type, <span v-highlightjs><code class="go" v-pre style="display: inline">GenericMap</code></span>, with a parameter list composed of 2 parameter types: <span v-highlightjs><code class="go" v-pre style="display: inline">K</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">V</code></span>. The first parameter type (<span v-highlightjs><code class="go" v-pre style="display: inline">K</code></span>) has the constraint ordered; the second parameter type has the type <span v-highlightjs><code class="go" v-pre style="display: inline">constraints.Integer</code></span>.</p>
<p>This new type has an underlying type which is a <span v-highlightjs><code class="go" v-pre style="display: inline">map</code></span>. Note that we can also create generic type structs (see figure).</p>
<figure>
<b-img :src="require('@/assets/images/generic-type-struct.jpg')" alt="A generic type struct"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">A generic type struct</figcaption>
</figure>
<p>Why create this new type? We already can create a map with a specific concrete type... The idea is to use that type to build function/methods that can be used on a lot of different map types: <span v-highlightjs><code class="go" v-pre style="display: inline">map[string]int32</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">map[string]uint8</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">map[int]int</code></span>,... etc. For instance, summing all the values in the map:</p>
<pre v-highlightjs><code class="go" v-pre >// generics/types

func (m GenericMap[K, V]) sum() V {
   var sum V
   for _, v := range m {
      sum = sum + v
   }
   return sum
}</code></pre>
<p>Then we can create two new variables of this type :</p>
<pre v-highlightjs><code class="go" v-pre >// generics/types

m := GenericMap[string, int]{
   &quot;foo&quot;: 42,
   &quot;bar&quot;: 44,
}

m2 := GenericMap[float32, uint8]{
   12.5: 0,
   2.2:  23,
}</code></pre>
<p>And then we can use the feature!</p>
<pre v-highlightjs><code class="go" v-pre >// generics/types

fmt.Println(m.sum())
// 86
fmt.Println(m2.sum())
// 23</code></pre>
<p>But let’s say now I want to create a new variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">map[string]uint</code></span>:</p>
<pre v-highlightjs><code class="go" v-pre >// generics/types

m3 := map[string]uint{
   &quot;foo&quot;: 10,
}</code></pre>
<p>Can I also benefit from the sum method? Can I do that :</p>
<pre v-highlightjs><code class="go" v-pre >fmt.Println(m3.sum()) </code></pre>
<p>The answer is no; that’s because the sum is only defined on elements of type <span v-highlightjs><code class="go" v-pre style="display: inline">GenericMap</code></span>. Fulfilling the constraints is insufficient; we will need to convert it to a <span v-highlightjs><code class="go" v-pre style="display: inline">GenericMap</code></span>. And it is done like that :</p>
<pre v-highlightjs><code class="go" v-pre >m4 := GenericMap[string, uint](m3)
fmt.Println(m4.sum())</code></pre>
<p>We use parenthesis to convert <span v-highlightjs><code class="go" v-pre style="display: inline">m3</code></span> to a valid <span v-highlightjs><code class="go" v-pre style="display: inline">GenericMap</code></span>. Please note that you will need to provide the parameter list and explicitly state the types <span v-highlightjs><code class="go" v-pre style="display: inline">string</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">uint</code></span> in this case.</p>
<div id="when-is-it-a-good-idea-to-use-generics" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> When is it a good idea to use generics? <a href="#when-is-it-a-good-idea-to-use-generics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>In the next two sections, I will replicate some advice given by Ian Lance Taylor in a talk given about generics when they came out<a href="#fn4" class="footnote-ref" id="fnref4" role="doc-noteref"><sup>4</sup></a>.</p>
<div id="first-use-case-when-you-write-almost-the-same-function-several-times" class="anchor"></div>
<h2 data-number="12.1"><span class="header-section-number">12.1</span> First use case: when you write almost the same function several times <a href="#first-use-case-when-you-write-almost-the-same-function-several-times"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When you write a method/function several times, the only thing that changes is the input/output type. In that case, you can write a generic function/method. You can replace a bunch of functions/methods with one generic construct!</p>
<p>Let’s take an example:</p>
<pre v-highlightjs><code class="go" v-pre >// generics/use-cases/same-fct2

func containsUint8(needle uint8, haystack []uint8) bool {
   for _, v := range haystack {
      if v == needle {
         return true
      }
   }
   return false
}

func containsInt(needle int, haystack []int) bool {
   for _, v := range haystack {
      if v == needle {
         return true
      }
   }
   return false
}</code></pre>
<p>Here we have two functions that check if an element is in a slice. What is changing between those two functions? The type of the slice element. This is a perfect use case to build a generic function!</p>
<pre v-highlightjs><code class="go" v-pre >// generics/use-cases/same-fct2

func contains[E constraints.Ordered](needle E, haystack []E) bool {
   for _, v := range haystack {
      if v == needle {
         return true
      }
   }
   return false
}</code></pre>
<p>We have created a generic function named contains. This function has one type parameter of type <span v-highlightjs><code class="go" v-pre style="display: inline">constraints.Ordered</code></span>. This means we can compare the two elements of the slice because we can use the operator <span v-highlightjs><code class="go" v-pre style="display: inline">==</code></span> with types that fulfill this constraint.</p>
<div id="second-use-case-on-collection-types-slices-maps-arrays" class="anchor"></div>
<h2 data-number="12.2"><span class="header-section-number">12.2</span> Second use case: on collection types : (slices, maps, arrays) <a href="#second-use-case-on-collection-types-slices-maps-arrays"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>When manipulating collection types in function, methods, or types, you might need to use generic. Some libraries have emerged to propose you generic collection types. We will discover some libraries in another section.</p>
<div id="third-use-case-data-structures" class="anchor"></div>
<h2 data-number="12.3"><span class="header-section-number">12.3</span> Third use case: data structures <a href="#third-use-case-data-structures"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>To understand this use case, we have to understand the definition of data structures (if you have never come across this) :</p>
<p>If we take the definition from Wikipedia: a data structure is a data organization, management, and storage format that is usually chosen for efficient access to data. More precisely, a data structure is a collection of data values, the relationships among them, and the functions or operations that can be applied to the data.</p>
<p>So a data structure is a way to store and organize data. And this data structure is also shipped with functions/operations that we can use on it.</p>
<p>The most common data structure we have seen is the map. A map allows us to store some data in a particular way, to retrieve and eventually delete it.</p>
<p>But there is a lot more than maps :</p>
<ul>
<li><p>The linked list: each element in this list points to the next one</p>
<ul>
<li>the context package is built around this data structure (see the chapter about context)</li>
</ul></li>
<li><p>The binary tree is a data structure that uses the graph theory, each element (called a node) has at most two children nodes.</p>
<ul>
<li>this data structure is used especially in search algorithms</li>
</ul></li>
<li><p>... many more data structures exist in computer science</p></li>
</ul>
<p>Why does it make sense to have a generic linked list? It makes sense because the data structure does not depend on what type of data you want to store. If you want to store integers in a linked list, the internals of the linked list will not differ from those operating on strings.</p>
<p>There is one interesting package that I discovered: https://github.com/zyedidia/generic. It covers a lot of data structures, do not hesitate to take a look at it.</p>
<div id="when-its-not-a-good-idea-to-use-generics" class="anchor"></div>
<h1 data-number="13"><span class="header-section-number">13</span> When it’s not a good idea to use generics? <a href="#when-its-not-a-good-idea-to-use-generics"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="when-you-can-use-a-basic-interface" class="anchor"></div>
<h2 data-number="13.1"><span class="header-section-number">13.1</span> When you can use a basic interface <a href="#when-you-can-use-a-basic-interface"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Sometimes you can use a basic interface to solve your problem, and using an interface makes your code easier to understand, especially for newcomers. Even if it was incomplete, Go before 1.18 already had a form of generic programming with interfaces. If you want a function to be used by ten types, check what you need those types to be capable of, then create an interface and implement it on your ten types.</p>
<p>Let’s take an example. Let’s say you have to save some data in a database. We use for that DynamoDb, an AWS database solution.</p>
<pre v-highlightjs><code class="go" v-pre >// generics/dynamo/main.go

func saveProduct(product Product, client *dynamodb.DynamoDB) error {
    marshalled, err := dynamodbattribute.MarshalMap(product)
    if err != nil {
        return fmt.Errorf(&quot;impossible to marshall product: %w&quot;, err)
    }
    marshalled[&quot;PartitionKey&quot;] = &amp;dynamodb.AttributeValue{
        S: aws.String(&quot;product&quot;),
    }
    marshalled[&quot;SortKey&quot;] = &amp;dynamodb.AttributeValue{
        S: aws.String(product.ID),
    }
    input := &amp;dynamodb.PutItemInput{
        Item:      marshalled,
        TableName: aws.String(tableName),
    }
    _, err = client.PutItem(input)
    if err != nil {
        return fmt.Errorf(&quot;impossible to save item in db: %w&quot;, err)
    }
    return nil
}</code></pre>
<p>Here we want to save a product. And to save it into DynamoDb, we need to get the item’s partition key and sort key. Those two keys are mandatory. So here, for the partition key, we use the string product and for the sort key, we use the product’s id.</p>
<p>But now, let’s say that I want to persist a category :</p>
<pre v-highlightjs><code class="go" v-pre >type Category struct {
   ID    string
   Title string
}</code></pre>
<p>I will need to create a new function to store it inside my DB. Because the first method is specific to the type product.</p>
<p>The solution here can be to create an interface. This interface will define a method to retrieve the partition key and the sort key :</p>
<pre v-highlightjs><code class="go" v-pre >type Storable interface {
   PartitionKey() string
   SortKey() string
}</code></pre>
<p>Then we can create a second version of our function.</p>
<pre v-highlightjs><code class="go" v-pre >func save(s Storable, client *dynamodb.DynamoDB) error {
    marshalled, err := dynamodbattribute.MarshalMap(s)
    if err != nil {
        return fmt.Errorf(&quot;impossible to marshall product: %w&quot;, err)
    }
    marshalled[&quot;PartitionKey&quot;] = &amp;dynamodb.AttributeValue{
        S: aws.String(s.PartitionKey()),
    }
    marshalled[&quot;SortKey&quot;] = &amp;dynamodb.AttributeValue{
        S: aws.String(s.SortKey()),
    }
    input := &amp;dynamodb.PutItemInput{
        Item:      marshalled,
        TableName: aws.String(tableName),
    }
    _, err = client.PutItem(input)
    if err != nil {
        return fmt.Errorf(&quot;impossible to save item in db: %w&quot;, err)
    }
    return nil
}</code></pre>
<p>We call the interface methods instead of relying on fields from the type. Then to use that function, we simply have to implement the interface on the product and category types :</p>
<pre v-highlightjs><code class="go" v-pre >type Product struct {
   ID    string
   Title string
}

func (p Product) PartitionKey() string {
   return &quot;product&quot;
}

func (p Product) SortKey() string {
   return p.ID
}

type Category struct {
   ID    string
   Title string
}

func (c Category) PartitionKey() string {
   return &quot;category&quot;
}

func (c Category) SortKey() string {
   return c.ID
}</code></pre>
<p>And we can call save inside our program:</p>
<pre v-highlightjs><code class="go" v-pre >err := saveProduct(teaPot, svc)
if err != nil {
   panic(err)
}
err = save(teaPot, svc)
if err != nil {
   panic(err)
}</code></pre>
<div id="when-the-implementation-of-a-method-is-different-for-each-type" class="anchor"></div>
<h2 data-number="13.2"><span class="header-section-number">13.2</span> When the implementation of a method is different for each type <a href="#when-the-implementation-of-a-method-is-different-for-each-type"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>It makes sense to use generics when the implementation is the same, but when the implementation is different, you have to write different functions for each implementation. Do not force generics into your code!</p>
<div id="some-generics-libraries-that-you-can-use-in-your-code" class="anchor"></div>
<h1 data-number="14"><span class="header-section-number">14</span> Some generics libraries that you can use in your code <a href="#some-generics-libraries-that-you-can-use-in-your-code"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Here is a non-exhaustive list of libraries where you can use some interesting generic function methods:</p>
<ul>
<li><p>https://github.com/zyedidia/generic : Provides a wide range of data structures ready to use</p></li>
<li><p>https://github.com/samber/lo: a library that implements a lot of useful functions in the style of lodash (a famous javascript library)</p></li>
<li><p>https://github.com/deckarep/golang-set: a library that provides a Set data structure that is fairly easy to use.</p></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="15"><span class="header-section-number">15</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="15.1"><span class="header-section-number">15.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What does the character tilde <span v-highlightjs><code class="go" v-pre style="display: inline">~</code></span> mean in <span v-highlightjs><code class="go" v-pre style="display: inline">~int</code></span>?</p></li>
<li><p>What does the character pipe <span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span> mean in <span v-highlightjs><code class="go" v-pre style="display: inline">~int | string</code></span>?</p></li>
<li><p>The empty interface has been replaced in Go 1.18 by <span v-highlightjs><code class="go" v-pre style="display: inline">any</code></span>. True or False?</p></li>
<li><p>When you call a generic function, you have to specify the type argument(s) you will use. Ex: I have to write <span v-highlightjs><code class="go" v-pre style="display: inline">myFunc[int, string](a,b)</code></span>. True or false?</p></li>
<li><p>Fill the blank. Let’s define the following function <span v-highlightjs><code class="go" v-pre style="display: inline">foo[T ~string](bar T) T</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> is a ________ with a ________ denoted <span v-highlightjs><code class="go" v-pre style="display: inline">~string</code></span>.</p></li>
<li><p>Type parameters only exist for functions and methods. True or false?</p></li>
<li><p>A type constraint can be a type struct. True or False?</p></li>
<li><p>Define the term type constraint.</p></li>
<li><p>Fill the blank. Generic functions may only _______ permitted by their type constraints.</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="15.2"><span class="header-section-number">15.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What does the character tilde <span v-highlightjs><code class="go" v-pre style="display: inline">~</code></span> mean in <span v-highlightjs><code class="go" v-pre style="display: inline">~int</code></span>?</p>
<ol type="1">
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">~int</code></span> denotes the int type by itself and also any named types whose underlying types are int</p></li>
<li><p>ex: <span v-highlightjs><code class="go" v-pre style="display: inline">type Score int</code></span> belongs to <span v-highlightjs><code class="go" v-pre style="display: inline">~int</code></span></p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">Score</code></span> is a named type, and its underlying type is <span v-highlightjs><code class="go" v-pre style="display: inline">int</code></span></p></li>
</ol></li>
<li><p>What does the character pipe <span v-highlightjs><code class="go" v-pre style="display: inline">|</code></span> mean in <span v-highlightjs><code class="go" v-pre style="display: inline">~int | string</code></span>?</p>
<ol type="1">
<li><p>It means union</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">~int | string</code></span> means all strings OR the int type by itself and also any named types whose underlying types are int</p></li>
</ol></li>
<li><p>The empty interface has been replaced in Go 1.18 by <span v-highlightjs><code class="go" v-pre style="display: inline">any</code></span>. True or False?</p>
<ol type="1">
<li><p>False</p></li>
<li><p>It has not been replaced. Imagine if it were the case, it would have broken a lot of existing programs and libraries!</p></li>
<li><p><span v-highlightjs><code class="go" v-pre style="display: inline">any</code></span> is an alias to the empty interface; it means the same thing. You can use any instead of interface{} and vice versa.</p></li>
</ol></li>
<li><p>When you call a generic function, you have to specify the type argument(s) you will use. Ex: I have to write <span v-highlightjs><code class="go" v-pre style="display: inline">myFunc[int, string](a,b)</code></span>. True or false?</p>
<ol type="1">
<li><p>This is usually false, but it can be true sometimes. Why?</p></li>
<li><p>When you provide the type parameters in your function call, you do not let the Go compiler infer those.</p></li>
<li><p>Go can infer those.</p></li>
</ol></li>
<li><p>Fill the blank. Let’s define the following function <span v-highlightjs><code class="go" v-pre style="display: inline">foo[T ~string](bar T) T</code></span>, <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> is a ________ with a ________ denoted <span v-highlightjs><code class="go" v-pre style="display: inline">~string</code></span>.</p>
<ol type="1">
<li><span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span>is a <strong>type parameter</strong> with a <strong>type constraint</strong> denoted <span v-highlightjs><code class="go" v-pre style="display: inline">~string</code></span>.</li>
</ol></li>
<li><p>Type parameters only exist for functions and methods. True or false?</p>
<ol type="1">
<li>False; they also exist for types; you can build a generic type with Go.</li>
</ol></li>
<li><p>A type constraint can be a type struct. True or False?</p>
<ol type="1">
<li><p>False.</p></li>
<li><p>A type constraint should be an interface.</p></li>
</ol></li>
<li><p>Define the term type constraint.</p>
<ol type="1">
<li>A type constraint is an interface that defines the set of permissible type arguments for the respective type parameter and controls the operations supported by values of that type parameter. (from the Go specs)</li>
</ol></li>
<li><p>Fill the blank. Generic functions may only _______ permitted by their type constraints.</p>
<ol type="1">
<li><p>Generic functions may only use types permitted by their type constraints.</p></li>
<li><p>A type constraint allows only certain types; they constrain the types that can be used by a generic function/method/type.</p></li>
</ol></li>
</ol>
<div id="key-takeways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="16"><span class="header-section-number">16</span> Key Takeways <a href="#key-takeways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Functions and methods can be generic.</p></li>
<li><p>A function/method is generic if it provides a type parameter list.</p></li>
<li><p>The type parameter list begins with open square brackets and ends with a closing square bracket.</p></li>
<li><p>Generic function: func foo[E myTypeConstraint](myVar E) E { ... }</p>
<ul>
<li><p>In this function foo, we have one type parameter named E.</p></li>
<li><p>This type parameter is of type constraint myTypeConstraint</p></li>
<li><p>The type parameter is named E; this is its identifier.</p></li>
<li><p>This type parameter is used as an argument and as a result.</p></li>
</ul></li>
<li><p>A type constraint is an interface that defines all the types you can use for a specific type parameter.</p></li>
<li><p>We designate the type constraint as a meta-type.</p></li>
<li><p>The type constraint is here to answer the question: which type do I have the right to use here?</p></li>
<li><p>Inside a type constraint, you can list all the types you allow the user of your function/method/type to use.</p>
<ul>
<li><p>You can use the pipe (|) character to make a union between type terms</p>
<ul>
<li><p>We can understand union as a logical OR.</p></li>
<li><p>int|string means: int OR string</p></li>
</ul></li>
<li><p>You can use the tilde character (~T) to denote the type T + all types whose underlying type is T.</p>
<ul>
<li><p>Example: ~int denotes the type int + all the types that have an underlying type equal to int</p></li>
<li><p>Example: type DegreeC int, the type DegreeC has an underlying type equal to int. We say that’s a named type.</p></li>
</ul></li>
</ul></li>
<li><p>When you call a generic function, you might need to provide the actual type of type parameter. But Go has the power to infer it. It is checked at compile time.</p></li>
<li><p>We can also build generic types.</p></li>
<li><p>When should you think about generics ?</p>
<ul>
<li><p>When you write the same function/method several times, the only thing that change is the input/output type.</p></li>
<li><p>When you want to use a well-known data structure (ex: binary tree, HashSet,...), you will find existing implementations on the web.</p></li>
<li><p>When you work with collection types like maps and slices, this is often (not always) a good use case.</p></li>
</ul></li>
<li><p>It would be best if you did not force generics into your code.</p></li>
<li><p>Do not forget that you can still use pure interfaces!</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>source: https://youtu.be/WzgLqE-3IhY?t=55<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn2" role="doc-endnote"><p>source: https://go.dev/project<a href="#fnref2" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn3" role="doc-endnote"><p>See https://github.com/golang/go/issues/43651<a href="#fnref3" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
<li id="fn4" role="doc-endnote"><p>https://www.youtube.com/watch?v=Pa_e9EeCdy8<a href="#fnref4" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap38Generics"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap37Context'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Context</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap39AnObjectOrientedProgrammingLanguage'}">
									<p><u><small>Next</small></u></p>
									<p><small>An object oriented programming language ?</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Generics - Practical Go Lessons"
const description = "This chapter covers Go generics. The ability for a function, method or type to provide type parameters based on type constraints"

export default {
  name: "Chap38Generics",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
